export function Loop(props: React.ComponentProps<'svg'>) {
  return (
    <svg viewBox="0 0 96 51" {...props}>
      <path
        d="M37.1236 0.609064C35.2798 1.10311 33.5506 2.17676 32.0484 3.79404C26.5678 9.67414 24.7912 21.6121 27.9234 31.5664C29.1076 35.3352 30.906 38.6197 33.226 41.3343C30.4379 41.4711 27.4537 41.2884 24.5125 40.7455C14.8076 38.9518 7.35342 33.8871 4.07134 26.8443L0 28.7431C3.90643 37.1182 12.5418 43.0986 23.6961 45.1608C28.5704 46.0634 33.4165 46.1017 37.6535 45.3849C40.497 47.4071 43.8129 48.8494 47.5182 49.6409C60.2881 52.3745 71.4411 49.5081 79.6824 44.6902C80.5745 46.3924 81.7169 48.1614 83.1461 49.9813L86.6743 47.205C85.3062 45.4617 84.254 43.7907 83.4619 42.2011C85.3748 40.7934 87.0695 39.2873 88.5255 37.758C93.8567 32.1446 96.3104 26.0526 95.0796 21.4593C94.3894 18.8834 92.6143 17.2259 90.2068 16.9177C86.47 16.4427 81.9931 19.4092 79.3121 24.1323C77.8003 26.7984 75.3876 32.7399 77.9155 40.5253C70.4777 45.0234 60.2514 47.7693 48.4576 45.2508C46.6822 44.8721 45.0866 44.3406 43.6512 43.6906C45.5619 42.8822 47.1939 41.8579 48.4535 40.6369C54.1353 35.1388 56.6646 23.0166 54.0849 13.606C52.1783 6.64215 47.8051 2.01322 41.7628 0.569161C40.1794 0.191321 38.6095 0.210918 37.1182 0.610518L37.1236 0.609064ZM38.7572 40.5672C35.2069 37.4905 33.2685 33.5751 32.2041 30.2101C29.2603 20.8506 31.4802 10.9792 35.3261 6.85075C36.946 5.10892 38.7614 4.46554 40.7181 4.93515C46.3202 6.27631 48.7222 11.0324 49.7509 14.7847C51.9027 22.6418 49.8776 33.0015 45.3253 37.4007C43.8566 38.8172 41.5419 39.8966 38.7504 40.5632L38.7572 40.5672ZM81.8354 37.8011C80.4983 32.3121 82.1472 28.2492 83.2238 26.3449C85.1137 23.0079 88.0257 21.1639 89.6389 21.371C89.8982 21.4062 90.4332 21.4721 90.7398 22.6163C91.3937 25.0567 89.9538 29.7262 85.2716 34.66C84.2505 35.7299 83.1039 36.7869 81.8285 37.7971L81.8354 37.8011Z"
        fill="currentColor"
      />
    </svg>
  );
}
